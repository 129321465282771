// All available fonts
// ...those which have been loaded already get an additional "loaded:true" flag
// @formatter:off (WebStorm ignore)
// prettier-ignore
export const ACCREDIBLE_FONTS = {
  'Acto':                 { type: 'custom', weights: [300, 400, 500, 600, 700],                                                                                             fallback: 'sans-serif', organization: 411 },
  'Actor':                { type: 'google', weights: [400],                                                                                                                 fallback: 'sans-serif' },
  'Alegreya SC':          { type: 'google', weights: [400, '400i', 500, '500i', 700, '700i'],                                                                               fallback: 'serif' },
  'Aleo':                 { type: 'google', weights: [300, '300i', 400, '400i', 700, '700i'],                                                                               fallback: 'serif' },
  'Allura':               { type: 'google', weights: [400],                                                                                                                 fallback: 'cursive' },
  'Anton':                { type: 'google', weights: [400],                                                                                                                 fallback: 'sans-serif' },
  'Antonio':              { type: 'custom', weights: [300, 400, 700],                                                                                                       fallback: 'sans-serif' },
  'Arial':                { type: 'system', weights: [400, '400i', 700, '700i'],                                                                                            fallback: '\'Helvetica Neue\', Helvetica, sans-serif' },
  'Asap':                 { type: 'google', weights: [400, '400i', 500, '500i', 600, '600i', 700, '700i'],                                                                  fallback: 'sans-serif' },
  'Avenir Next':          { type: 'custom', weights: [200, '200i', 400, '400i', 500, '500i', 600, '600i', 700, '700i', 800, '800i'],                                        fallback: 'sans-serif', organization: 1566 },
  'Barlow':               { type: 'google', weights: [200, '200i', 300, '300i', 400, '400i', 700, '700i'],                                                                  fallback: 'sans-serif' },
  'Bebas Neue':           { type: 'google', weights: [400],                                                                                                                 fallback: 'sans-serif' },
  'Bilbo Swash Caps':     { type: 'google', weights: [400],                                                                                                                 fallback: 'cursive' },
  'Bitter':               { type: 'google', weights: [200, '200i', 400, '400i', 600, '600i', 700, '700i'],                                                                  fallback: 'serif' },
  'Brandon Grotesque':    { type: 'custom', weights: [400, 600, 700],                                                                                                       fallback: 'sans-serif' },
  'Bree Serif':           { type: 'google', weights: [400],                                                                                                                 fallback: 'serif' },
  'British Council Sans': { type: 'custom', weights: [400, 700],                                                                                                            fallback: 'sans-serif', organization: 30 },
  'Cairo':                { type: 'google', weights: [200, 300, 400, 500, 600, 700, 800, 900],                                                                              fallback: 'sans-serif' },
  'Caveat':               { type: 'google', weights: [400, '400i', 600, '600i', 700, '700i'],                                                                               fallback: 'sans-serif' },
  'Cinzel':               { type: 'google', weights: [400, 700],                                                                                                            fallback: 'serif' },
  'Centra No.1':          { type: 'custom', weights: [100, 400, 500],                                                                                                       fallback: 'sans-serif', organization: 13203 },
  'Charm':                { type: 'google', weights: [400, 700],                                                                                                            fallback: 'cursive' },
  'Chivo':                { type: 'google', weights: [100, '100i', 200, '200i', 300, '300i', 400, '400i', 500, '500i', 600, '600i', 700, '700i', 800, '800i', 900, '900i'], fallback: 'sans-serif' },
  'Cormorant Garamond':   { type: 'google', weights: [400, '400i', 600, '600i', 700, '700i'],                                                                               fallback: 'serif' },
  'Cormorant SC':         { type: 'google', weights: [400, '400i', 600, '600i', 700, '700i'],                                                                               fallback: 'serif' },
  'Crimson Text':         { type: 'google', weights: [400, '400i', 600, '600i', 700, '700i'],                                                                               fallback: 'serif' },
  'Dancing Script':       { type: 'google', weights: [400, 700],                                                                                                            fallback: 'cursive' },
  'DM Sans':              { type: 'google', weights: [400, '400i', 500, '500i', 700, '700i'],                                                                               fallback: 'sans-serif' },
  'DM Serif Display':     { type: 'google', weights: [400, '400i'],                                                                                                         fallback: 'serif' },
  'Domine':               { type: 'google', weights: [400, 700],                                                                                                            fallback: 'serif' },
  'EB Garamond':          { type: 'google', weights: [400, '400i', 500, '500i', 600, '600i', 700, '700i', 800, '800i'],                                                     fallback: 'serif' },
  'Encode Sans':          { type: 'google', weights: [300, 400, 600, 700, 800],                                                                                             fallback: 'serif' },
  'Epilogue':             { type: 'google', weights: [400, '400i', 500, '500i', 600, '600i', 700, '700i'],                                                                  fallback: 'sans-serif' },
  'Felipa':               { type: 'google', weights: [400],                                                                                                                 fallback: 'cursive' },
  'Figtree':              { type: 'google', weights: [300, '300i', 400, '400i', 500, '500i', 600, '600i', 700, '700i', 800, '800i', 900, '900i'],                           fallback: 'sans-serif' },
  'Fira Mono':            { type: 'google', weights: [400, 500, 700],                                                                                                       fallback: 'monospace' },
  'Fira Sans':            { type: 'google', weights: [100, '100i', 200, '200i', 300, '300i', 400, '400i', 500, '500i', 600, '600i', 700, '700i', 800, '800i', 900, '900i'], fallback: 'serif' },
  'Georgia':              { type: 'system', weights: [400, '400i', 700, '700i'],                                                                                            fallback: '\'Times New Roman\', Times, serif' },
  'Gotham':               { type: 'custom', weights: [200, '200i', 300, '300i', 400, '400i', 500, '500i', 700, '700i', 800, '800i'],                                        fallback: 'sans-serif', organization: 675 },
  'Graphik':              { type: 'custom', weights: [400],                                                                                                                 fallback: 'sans-serif', organization: 13203 },
  'Great Vibes':          { type: 'google', weights: [400],                                                                                                                 fallback: 'cursive' },
  'GT Pressura Mono':     { type: 'custom', weights: [400, 700],                                                                                                            fallback: 'sans-serif', organization: 23259},
  'Heebo':                { type: 'google', weights: [400, '400i', 600, '600i', 700, '700i'],                                                                               fallback: 'sans-serif' },
  'IBM Plex Sans':        { type: 'google', weights: [300, '300i', 400, '400i', 600, '600i', 700, '700i'],                                                                  fallback: 'sans-serif' },
  'IBM Plex Serif':       { type: 'google', weights: [300, '300i', 400, '400i', 600, '600i', 700, '700i'],                                                                  fallback: 'serif' },
  'IM Fell Great Primer': { type: 'google', weights: [400, '400i'],                                                                                                         fallback: 'serif' },
  'Indie Flower':         { type: 'google', weights: [400, 700],                                                                                                            fallback: 'cursive' },
  'Inter':                { type: 'google', weights: [200, '200i', 300, '300i', 400, '400i', 700, '700i'],                                                                  fallback: 'sans-serif' },
  'Josefin Sans':         { type: 'google', weights: [400, '400i', 600, '600i'],                                                                                            fallback: 'sans-serif' },
  'Jost':                 { type: 'google', weights: [300, '300i', 400, '400i', 600, '600i', 700, '700i'],                                                                  fallback: 'sans-serif' },
  'Lato':                 { type: 'google', weights: [400, '400i', 700, '700i'],                                                                                            fallback: 'sans-serif' },
  'Larken':               { type: 'custom', weights: [300, 700],                                                                                                            fallback: 'serif', organization: 147411},
  'Libre Baskerville':    { type: 'google', weights: [400, '400i', 700],                                                                                                    fallback: 'serif' },
  'Libre Caslon Text':    { type: 'google', weights: [400, '400i', 700],                                                                                                    fallback: 'serif' },
  'Lora':                 { type: 'google', weights: [400, '400i', 700, '700i'],                                                                                            fallback: 'serif' },
  'Monospace':            { type: 'system', weights: [400, '400i', 700, '700i'],                                                                                            fallback: 'monospace' },
  'Montserrat':           { type: 'google', weights: [100, '100i', 200, '200i', 300, '300i', 400, '400i', 500, '500i', 600, '600i', 700, '700i', 800, '800i', 900, '900i'], fallback: 'sans-serif' },
  'Noto Sans':            { type: 'google', weights: [400, '400i', 700, '700i'],                                                                                            fallback: 'sans-serif' },
  'Noto Sans JP':         { type: 'google', weights: [100, 300, 400, 500, 700, 900],                                                                                        fallback: 'sans-serif' },
  'Noto Sans SC':         { type: 'google', weights: [100, 300, 400, 500, 700, 900],                                                                                        fallback: 'sans-serif' },
  'Noto Serif JP':        { type: 'google', weights: [200, 300, 400, 500, 600, 700, 900],                                                                                   fallback: 'serif' },
  'Nunito':               { type: 'google', weights: [200, '200i', 300, '300i', 400, '400i', 700, '700i'],                                                                  fallback: 'sans-serif' },
  'Nunito Sans':          { type: 'google', weights: [200, '200i', 300, '300i', 400, '400i', 700, '700i'],                                                                  fallback: 'sans-serif' },
  'Open Sans':            { type: 'google', weights: [300, '300i', 400, '400i', 700],                                                                                       fallback: 'sans-serif' },
  'Open Sauce Sans':      { type: 'custom', weights: [300, '300i', 400, '400i', 500, '500i', 600, '600i', 700, '700i', 800, '800i', 900, '900i'],                           fallback: 'sans-serif', organization: 147411},
  'Oswald':               { type: 'google', weights: [400],                                                                                                                 fallback: 'sans-serif' },
  'Outfit':               { type: 'google', weights: [100, '100i', 200, '200i', 300, '300i', 400, '400i', 500, '500i', 600, '600i', 700, '700i', 800, '800i', 900, '900i'], fallback: 'sans-serif' },
  'Overpass':             { type: 'google', weights: [300, '300i', 400, '400i', 600, '600i', 700, '700i'],                                                                  fallback: 'sans-serif' },
  'Pinyon Script':        { type: 'google', weights: [400, 700],                                                                                                            fallback: 'cursive' },
  'Plain Black Wide':     { type: 'custom', weights: [400],                                                                                                                 fallback: 'cursive' },
  'Playball':             { type: 'google', weights: [400],                                                                                                                 fallback: 'cursive' },
  'Playfair Display':     { type: 'google', weights: [400, '400i', 700, '700i'],                                                                                            fallback: 'serif' },
  'Playfair Display SC':  { type: 'google', weights: [400, '400i', 700, '700i'],                                                                                            fallback: 'serif' },
  'Plus Jakarta Sans':    { type: 'google', weights: [200, '200i', 300, '300i', 400, '400i', 500, '500i', 600, '600i', 700, '700i', 800, '800i'],                           fallback: 'sans-serif' },
  'Poppins':              { type: 'google', weights: [300, '300i', 400, '400i', 700, '700i'],                                                                               fallback: 'sans-serif' },
  'PPFormula':            { type: 'custom', weights: [500],                                                                                                                 fallback: 'sans-serif', organization: 34765 },
  'PPMori':               { type: 'custom', weights: [400],                                                                                                                 fallback: 'sans-serif', organization: 23259 },
  'PT Sans':              { type: 'google', weights: [400, '400i', 700, '700i'],                                                                                            fallback: 'sans-serif' },
  'PT Serif':             { type: 'google', weights: [400, '400i', 700, '700i'],                                                                                            fallback: 'serif' },
  'Public Sans':          { type: 'google', weights: [200, '200i', 300, '300i', 400, '400i', 500, '500i', 600, '600i', 700, '700i', 800, '800i'],                           fallback: 'sans-serif' },
  'Qualtrics Grotesque':  { type: 'custom', weights: [200, 300, 400, 700],                                                                                                  fallback: 'sans-serif' },
  'Questrial':            { type: 'custom', weights: [400],                                                                                                                 fallback: 'sans-serif' },
  'Rajdhani':             { type: 'google', weights: [300, 400, 500, 600, 700],                                                                                             fallback: 'sans-serif' },
  'Raleway':              { type: 'google', weights: [400, '400i', 700, '700i'],                                                                                            fallback: 'sans-serif' },
  'Roboto':               { type: 'google', weights: [300, '300i', 400, '400i', 700, '700i'],                                                                               fallback: 'sans-serif' },
  'Roboto Condensed':     { type: 'google', weights: [300, '300i', 400, '400i', 700, '700i'],                                                                               fallback: 'sans-serif' },
  'Roboto Slab':          { type: 'google', weights: [400, '400i', 600, '600i', 700, '700i'],                                                                               fallback: 'serif' },
  'Sacramento':           { type: 'google', weights: [400],                                                                                                                 fallback: 'cursive' },
  'sans-serif':           { type: 'system', weights: [400, '400i', 700, '700i'],                                                                                            fallback: 'sans-serif' },
  'Source Sans Pro':      { type: 'google', weights: [300, '300i', 400, '400i', 600, '600i', 700, '700i'],                                                                  fallback: 'sans-serif' },
  'Spectral':             { type: 'google', weights: [200, '200i', 300, '300i', 400, '400i', 500, '500i', 600, '600i', 700, '700i', 800, '800i'],                           fallback: 'serif' },
  'Syne':                 { type: 'google', weights: [400, 500, 600, 700, 800],                                                                                             fallback: 'sans-serif' },
  'Tangerine':            { type: 'google', weights: [400, 700],                                                                                                            fallback: 'cursive'},
  'Times New Roman':      { type: 'system', weights: [400, '400i', 700, '700i'],                                                                                            fallback: 'Times, Baskerville, Georgia, serif' },
  'Titillium Web':        { type: 'google', weights: [200, '200i', 300, '300i', 400, '400i', 700, '700i'],                                                                  fallback: 'sans-serif' },
  'UnifrakturMaguntia':   { type: 'google', weights: [400],                                                                                                                 fallback: 'cursive' },
  'Urbanist':             { type: 'google', weights: [100, '100i', 200, '200i', 300, '300i', 400, '400i', 500, '500i', 600, '600i', 700, '700i', 800, '800i', 900, '900i'], fallback: 'sans-serif' },
  'Varela Round':         { type: 'google', weights: [400],                                                                                                                 fallback: 'sans-serif' },
  'Verdana':              { type: 'system', weights: [400, '400i', 700, '700i'],                                                                                            fallback: 'Geneva, sans-serif' },
  'Work Sans':            { type: 'google', weights: [100, '100i', 200, '200i', 300, '300i', 400, '400i', 500, '500i', 600, '600i', 700, '700i', 800, '800i', 900, '900i'], fallback: 'sans-serif' },
}
