import { environment } from '@accredible-frontend-v2/envs';
import { AccredibleDesign, AccredibleGroup } from '@accredible-frontend-v2/models';
import { getXSignature } from '@accredible-frontend-v2/utils/api-signature';
import { getDateNowInSeconds } from '@accredible-frontend-v2/utils/date-helper';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ImageableCredential } from '../models/renderer.models';

const API_X_SIGNATURE = 'X-Signature';
const API_X_TIMESTAMP = 'X-Timestamp';

@Injectable()
export class RendererService {
  apiUrl = environment.apiUrl;

  constructor(private readonly _http: HttpClient) {}

  loadCredential(
    credentialId: string,
    imageType: 'certificate' | 'badge',
    key: string,
  ): Observable<ImageableCredential> {
    const endpoint = `/v1/recipient/credentials/${credentialId}`;
    const dateNowInSeconds = getDateNowInSeconds();
    const signature = getXSignature('GET', endpoint, dateNowInSeconds);

    const headers = {
      'x-accredible-renderer': '1',
      [API_X_SIGNATURE]: signature,
      [API_X_TIMESTAMP]: dateNowInSeconds,
    };

    let url = this.apiUrl + endpoint;
    if (key) {
      url += `?key=${key}`;
    }

    return new Observable<ImageableCredential>((observer) => {
      this._http.get(url, { headers }).subscribe({
        next: (res: { credential: ImageableCredential }) => {
          res.credential.imageType = imageType;
          observer.next(res.credential);
          observer.complete();
        },
        error: () => {
          console.log('Failed to load credential');
          observer.complete();
        },
      });
    });
  }

  loadDesign(
    designId: string,
    partialCredential: ImageableCredential,
  ): Observable<ImageableCredential> {
    const url = `${this.apiUrl}/v1/design/render_get/${designId}`;

    return new Observable<ImageableCredential>((observer) => {
      this._http.get(url).subscribe({
        next: (res: { design: AccredibleDesign }) => {
          partialCredential.imageType = <'badge' | 'certificate'>res.design.kind;

          partialCredential.group = partialCredential.group || <AccredibleGroup>{};
          if (partialCredential.imageType === 'certificate') {
            partialCredential.group.certificate_design = res.design;
          } else if (partialCredential.imageType === 'badge') {
            partialCredential.group.badge_design = res.design;
          }

          observer.next(partialCredential);
          observer.complete();
        },
        error: () => {
          console.log('Failed to load design');
          observer.complete();
        },
      });
    });
  }

  loadGroup(
    groupUuid: string,
    designKind: 'badge' | 'certificate' | 'group_appearance',
  ): Observable<ImageableCredential> {
    const endpoint = `/v1/recipient/groups/by_uuid?uuid=${groupUuid}`;
    const dateNowInSeconds = getDateNowInSeconds();
    const signature = getXSignature('GET', endpoint, dateNowInSeconds);

    const headers = {
      'x-accredible-renderer': '1',
      [API_X_SIGNATURE]: signature,
      [API_X_TIMESTAMP]: dateNowInSeconds,
    };

    const url = this.apiUrl + endpoint;

    return new Observable<ImageableCredential>((observer) => {
      this._http.get(url, { headers }).subscribe({
        next: (res: { group: AccredibleGroup }) => {
          const partialCredential = <ImageableCredential>{
            imageType: designKind,
            group: res.group,
          };

          observer.next(partialCredential);
          observer.complete();
        },
        error: () => {
          console.log('Failed to load group');
          observer.complete();
        },
      });
    });
  }
}
