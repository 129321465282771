import { AccredibleCredential, AccredibleDesignBlock } from '@accredible-frontend-v2/models';
import { AccredibleAttributeService } from '@accredible-frontend-v2/services/attribute';
import { isStringHtml } from '@accredible-frontend-v2/utils/string-helper';
import { DatePipe, DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  input,
  OnInit,
  output,
} from '@angular/core';
import { ProcessingData } from '../certificate/certificate.component';
import { applyTextScaling } from './text-scaling.helper';

@Component({
  selector: 'accredible-block-text',
  templateUrl: './block-text.component.html',
  styleUrls: [`./block-text.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe],
})
export class BlockTextComponent implements AfterViewInit, OnInit {
  private readonly _document = inject(DOCUMENT);
  private readonly _hostElement = inject(ElementRef<HTMLElement>);
  private readonly _attribute = inject(AccredibleAttributeService);

  block = input<AccredibleDesignBlock>();
  partialCredential = input<Partial<AccredibleCredential>>();

  updateProcessing = output<ProcessingData>();

  text: string;
  isTextHtml = false;

  ngOnInit(): void {
    const attributeStr = this.block().model || this.block().custom_image_attribute;
    if (!!attributeStr) {
      this.text = this._attribute.replaceAttributes(
        attributeStr,
        this.partialCredential(),
        this.block().date_format,
      );
      this.isTextHtml = isStringHtml(this.text);
    }
  }

  ngAfterViewInit(): void {
    if (!this.block().scale_to_fit) {
      return;
    }

    // Scaling
    const blockElement = this._hostElement.nativeElement;
    const blockElementParent = blockElement.parentElement;
    blockElementParent.style.visibility = 'hidden';

    const fontFamily = getComputedStyle(blockElementParent).fontFamily;
    const fontName = this._extractFontName(fontFamily);

    this._scaleTextWhenFontIsAvailable(blockElement, fontName).finally(() => {
      // Ensure visibility is restored regardless of scaling success
      blockElementParent.style.visibility = 'visible';
      // Notify the parent that text scaling is complete
      this.updateProcessing.emit({ type: 'text', increment: -1 });
    });
  }

  private _extractFontName(fontFamily: string): string {
    return fontFamily.split(',')[0].replace(/^"(.*)"$/, '$1');
  }

  private async _scaleTextWhenFontIsAvailable(
    blockElement: HTMLElement,
    fontName: string,
  ): Promise<void> {
    if (this._isFontAvailable(fontName)) {
      // Scale immediately
      applyTextScaling(blockElement);
    } else {
      // Wait until the font has loaded, or timeout after 2 seconds
      await this._retryUntilFontIsAvailable(fontName, 2000);
      if (this._isFontAvailable(fontName)) {
        applyTextScaling(blockElement);
      }
    }
  }

  private _retryUntilFontIsAvailable(fontName: string, timeoutMs: number): Promise<void> {
    const intervalMs = 200;
    const maxRetries = timeoutMs / intervalMs;
    let attempts = 0;

    return new Promise((resolve) => {
      const intervalId = setInterval(() => {
        attempts++;
        if (this._isFontAvailable(fontName) || attempts >= maxRetries) {
          clearInterval(intervalId);
          resolve();
        }
      }, intervalMs);
    });
  }

  private _isFontAvailable(fontName: string): boolean {
    const failSafes = ['serif', 'sans-serif', 'cursive', 'monospace'];
    if (failSafes.indexOf(fontName.toLowerCase()) > -1) {
      // Didn't use this generally as this always seems to return true, but here it's all we've got
      return (this._document as any).fonts.check('1em ' + fontName);
    }

    // Creating our in-memory Canvas element where the magic happens
    let canvas = this._document.createElement('canvas');
    const context = canvas.getContext('2d');
    // The text whose final pixel size I want to measure
    const text = 'abcdefghijklmnopqrstuvwxyz0123456789';
    // Specifying the baseline font
    context.font = '72px monospace';
    // Checking the size of the baseline text
    const baselineSize = context.measureText(text).width;
    // Specifying the font whose existence we want to check
    context.font = '72px ' + fontName + ', monospace';
    // Checking the size of the font we want to check
    const newSize = context.measureText(text).width;
    // Removing the Canvas element we created
    canvas = null;

    return newSize !== baselineSize;
  }
}
