import { AccredibleFontService } from '@accredible-frontend-v2/services/font';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import Bugsnag from '@bugsnag/js';
import { renderSVG } from 'uqr';
import { v4 } from 'uuid';

// Created by V1 to distinguish between qr code placeholder and other svg elements
export const QR_CODE_BLOCK_ID = 'acc-badge-qr-code-placeholder';
export const CUSTOM_IMAGE_ATTRIBUTE = 'custom-image-attribute';

@Injectable({
  providedIn: 'root',
})
export class AccredibleSvgProcessorService {
  private _eventHandlers = [
    'onclick',
    'ondblclick',
    'onmousedown',
    'onmouseup',
    'onmouseover',
    'onmousemove',
    'onmouseout',
    'ondragstart',
    'ondrag',
    'ondragenter',
    'ondragleave',
    'ondragover',
    'ondrop',
    'ondragend',
    'onkeydown',
    'onkeypress',
    'onkeyup',
    'onload',
    'onunload',
    'onabort',
    'onerror',
    'onresize',
    'onscroll',
    'onselect',
    'onchange',
    'onsubmit',
    'onreset',
    'onfocus',
    'onblur',
    'ontouchstart',
    'ontouchmove',
    'ontouchleave',
    'ontouchend',
    'ontouchcancel',
  ];

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _sanitizer: DomSanitizer,
    private _font: AccredibleFontService,
  ) {}

  // Trim quotation marks from a string
  private static _trimQuoteMarks(str: string): string {
    return str.replace(/(^["'])|(["']$)/g, '');
  }

  // When fitting to width, the height of the content div needs to match its current line height multiplied by the number of lines
  private static _fitWidthCalculateMaxHeight(contentDiv: HTMLElement, lineCount: number): number {
    return parseFloat(getComputedStyle(contentDiv).getPropertyValue('line-height')) * lineCount;
  }

  sanitize(svg: string, title: string): string {
    const content = this._document.createElement('div');
    content.innerHTML = svg;

    // Remove circular text guides
    const textNodes = content.querySelectorAll('text');
    textNodes.forEach((node) => {
      if (node.nextSibling?.nodeName.toLowerCase() === 'path') {
        node.nextSibling.remove();
      }
    });

    // Remove all script nodes
    content.querySelectorAll('script').forEach((node) => {
      node.remove();
    });

    // Remove all event handlers
    this._eventHandlers.forEach((eventHandler) => {
      content.querySelectorAll('[' + eventHandler + ']').forEach((node) => {
        node.removeAttribute(eventHandler);
      });
    });

    // Hide internal text and inject title for screen readers
    content.querySelectorAll('div').forEach((node) => {
      node.setAttribute('aria-hidden', 'true');
    });
    // TODO: Is this necessary?
    content.querySelectorAll('style').forEach((node) => {
      node.setAttribute('aria-hidden', 'true');
    });

    // To stop IE11 erroneously focussing on our SVG
    content.querySelector('svg').setAttribute('focusable', 'false');

    let html = content.innerHTML;
    if (title) {
      // Inject title for accessibility
      html = html.replace(
        /^<svg(.*?)>/,
        '<svg$1><title>' + this._sanitizer.sanitize(SecurityContext.HTML, title) + '</title>',
      );
    }

    return html;
  }

  // TODO: This will be called from the badge editor, check if it's working correctly
  // Ensures the SVG includes all the correct fonts - adapted from svgEditorFontComponentExportCallback in svg-editor-font.component.js
  insertFonts(svg: string): string {
    // Compile a list of all fonts, weights and styles by looping through all <foreignObject> elements
    const svgFonts: Record<string, unknown> = {};
    const content = this._document.createElement('div');
    content.innerHTML = svg;

    // Text div
    content.querySelectorAll('foreignObject').forEach((foreignObject) => {
      this._extractFont(foreignObject, svgFonts);
    });

    // Text on path
    content.querySelectorAll('text').forEach((textObject) => {
      this._extractFont(textObject.parentElement, svgFonts);
    });

    // Create the <style>
    let svgStyle: string[] = [];
    Object.keys(svgFonts).forEach((fontName) => {
      const fontVariantsArray = Object.keys(svgFonts[fontName]);
      const getCssImportString = this._font.getCssImportString(fontName, fontVariantsArray);
      if (getCssImportString) {
        svgStyle.push(getCssImportString);
      }
    });

    // Make sure the @imports are first
    svgStyle = svgStyle.sort((a, b) => {
      if (a.indexOf('@import') === 0 && b.indexOf('@import') === 0) {
        return 0;
      }
      if (a.indexOf('@import') === 0 && b.indexOf('@font-face') === 0) {
        return -1;
      }
      if (a.indexOf('@font-face') === 0 && b.indexOf('@import') === 0) {
        return 1;
      }
      // if (a.indexOf('@font-face') === 0 && b.indexOf('@font-face') === 0) {
      return 0;
    });

    // Update the <style>
    content.querySelectorAll('style').forEach((node) => {
      node.remove();
    });
    if (svgStyle.length) {
      content.querySelector('svg').prepend('<style>\n' + svgStyle.join('\n') + '</style>');
    }

    return content.innerHTML;
  }

  shrinkTextBoxContentToFit(foreignObject: SVGForeignObjectElement, designId: number): void {
    const shrinkToFitType = foreignObject.getAttribute('shrink-to-fit'); // The value of shrink-to-fit can be width or height
    const contentDiv = <HTMLElement>foreignObject.firstElementChild; // first child of foreignObject SHOULD be a div

    // If the first child of the foreignObject is not a div, we don't do anything
    // For example, a foreignObject inside another foreignObject can lead to a memory leak
    if (contentDiv.tagName.toLowerCase() !== 'div') {
      Bugsnag.notify(
        'Design ID: ' +
          designId +
          '. First element was not a div. Element was: ' +
          contentDiv.tagName,
      );
      return;
    }

    contentDiv.style.fontSize = ''; // Always remove any existing font size and re-apply it if necessary - font size is assumed to be in px

    if (shrinkToFitType === 'width' || shrinkToFitType === 'height') {
      // The child should not initially have styles. If it does, we remove them
      if (contentDiv.hasAttribute('style')) {
        // TODO: The following Bugsnag notify was throwing too many events,
        //  research why so many credentials have styling on foreignObjects
        // Bugsnag.notify(
        //   'Design ID: ' +
        //     designId +
        //     '. Unwanted style attribute. Style was: ' +
        //     contentDiv.getAttribute('style'),
        // );
        contentDiv.removeAttribute('style');
      }

      let lineCount = 0;
      let maxHeight: number; // This is the maxHeight the child can have

      if (shrinkToFitType === 'width') {
        // Each div corresponds to a line
        lineCount = contentDiv.querySelectorAll('div').length || 1;
        maxHeight = AccredibleSvgProcessorService._fitWidthCalculateMaxHeight(
          contentDiv,
          lineCount,
        );
      } else {
        // If shrinkToFit is set to "height", then the maxHeight will be the height of the foreignObject
        maxHeight = +foreignObject.getAttribute('height');
      }

      let fontSize = parseFloat(getComputedStyle(foreignObject).getPropertyValue('font-size'));

      // If the child is bigger than the allowed maxHeight, we need to recalculate its font-size
      // which in turn makes the height go down, until it is shorter than the maxHeight
      while (parseFloat(getComputedStyle(contentDiv).getPropertyValue('height')) > maxHeight) {
        fontSize--;
        contentDiv.style.fontSize = fontSize + 'px';

        // If we need to fit the width, we recalculate the maxHeight after each passing,
        // Otherwise the calculations are thrown off and the content never really fits as it should
        // with the while loop stopping "sooner" than it should
        if (shrinkToFitType === 'width') {
          maxHeight = AccredibleSvgProcessorService._fitWidthCalculateMaxHeight(
            contentDiv,
            lineCount,
          );
        }
      }
    }
  }

  verticalAlignTextBoxContent(textNode: SVGForeignObjectElement): void {
    const verticalAlign = textNode.getAttribute('vertical-align');
    const contentDiv = <HTMLElement>textNode.firstElementChild; // first child of foreignObject is the div
    contentDiv.style.paddingTop = ''; // Always remove any top padding and re-apply it if necessary

    if (verticalAlign === 'middle' || verticalAlign === 'bottom') {
      const textHeight = parseFloat(
        getComputedStyle(textNode.querySelector('div')).getPropertyValue('height'),
      );
      const nodeHeight = parseFloat(textNode.getAttribute('height'));
      const remainingHeight = nodeHeight - textHeight;
      if (remainingHeight > 0) {
        contentDiv.style.paddingTop =
          (verticalAlign === 'middle' ? remainingHeight / 2 : remainingHeight) + 'px';
      }
    }
  }

  replaceIds(svgString: string): string {
    // match strings that look like: id="example_id"
    const ids = svgString.match(/(id="[^"]+")/g);

    if (ids) {
      for (let id of ids) {
        id = id.replace(/(\"|id=)/g, '');
        // Leaving the qr code placeholder id as is
        if (id === QR_CODE_BLOCK_ID) {
          continue;
        }

        const regExp = new RegExp(id, 'g');
        const updatedId = `accredible_badge_id_${v4()}`;

        svgString = svgString.replace(regExp, updatedId);
      }
    }

    return svgString;
  }

  replaceQRCode(svgString: string, url: string): string {
    const { element: badgeSvgEl, wrapper } = this._createSvgWrapper(svgString);
    const placeholder = badgeSvgEl.querySelector(`svg#${QR_CODE_BLOCK_ID}`);

    if (placeholder && url) {
      const qrCodeSvgString = renderSVG(url, {
        border: 3,
        whiteColor: getChildFillColor(placeholder, 'rect', '#ffffff'),
        blackColor: getChildFillColor(placeholder, 'g', '#000000'),
      });

      const { element: qrCodeSvgEl } = this._createSvgWrapper(qrCodeSvgString);

      // x, y, width and height
      setBasicAttributes(qrCodeSvgEl, placeholder);

      // Clone the rendered qr code and replace the placeholder with it
      badgeSvgEl.removeChild(placeholder);
      badgeSvgEl.appendChild(qrCodeSvgEl);
    }

    return wrapper.innerHTML;
  }

  addCustomImages(svgString: string): string {
    const { element: badgeSvgEl, wrapper } = this._createSvgWrapper(svgString);
    const customImagePlaceholders = wrapper.querySelectorAll(
      `foreignObject.${CUSTOM_IMAGE_ATTRIBUTE}`,
    );

    customImagePlaceholders.forEach((placeholder) => {
      const src = placeholder.querySelector('div')?.textContent;

      if (src) {
        const image = document.createElement('image');

        // Set the image's href
        image.setAttribute('xlink:href', src);

        // Stretch the image to fit original width & height
        // TODO(Salim): Discuss this with the team
        image.setAttribute('preserveAspectRatio', 'none');

        // x, y, width and height
        setBasicAttributes(image, <HTMLElement>placeholder);

        // Finally we need to add the image and get rid of the placeholder
        badgeSvgEl.append(image.cloneNode(true));
      }

      badgeSvgEl.removeChild(placeholder);
    });

    return wrapper.innerHTML;
  }

  private _extractFont(
    element: SVGForeignObjectElement | HTMLElement,
    svgFonts: Record<string, any>,
  ): void {
    const fontFamily = AccredibleSvgProcessorService._trimQuoteMarks(element.style.fontFamily);
    if (!svgFonts[fontFamily]) {
      svgFonts[fontFamily] = {};
    }
    const nearestWeight = this._font.getNearestWeight(fontFamily, element.style.fontWeight);
    const slug =
      nearestWeight +
      (element.style.fontStyle === 'italic' && this._font.hasItalicWeight(fontFamily, nearestWeight)
        ? 'i'
        : '');
    svgFonts[fontFamily][slug] = true;
  }

  private _createSvgWrapper(svgString: string): {
    element: SVGSVGElement;
    wrapper: HTMLElement;
  } {
    const wrapper = this._document.createElement('div');
    wrapper.innerHTML = svgString;
    const element = wrapper.querySelector('svg');

    return { element, wrapper };
  }
}

export const getChildFillColor = (
  source: Element,
  sourceSelector: string,
  fallback: string,
): string => {
  return source.querySelector(sourceSelector)?.getAttribute('fill') || fallback;
};

export const setBasicAttributes = (target: Element, source: Element): void => {
  if (!target || !source) {
    return;
  }

  const attributes = ['x', 'y', 'width', 'height'];
  attributes.forEach((attribute) => {
    target.setAttribute(attribute, source.getAttribute(attribute));
  });
};
