export const applyTextScaling = (blockElement: HTMLElement): void => {
  const blockElementParent = blockElement.parentElement;
  const blockElementParentWidth = blockElementParent.offsetWidth;

  const text = blockElement.textContent || '';

  // Cache computed styles
  const computedStyle = getComputedStyle(blockElementParent);
  const originalFontSize = parseFloat(computedStyle.fontSize);
  const { fontFamily, fontWeight } = computedStyle;
  let fontSize = originalFontSize;

  // Create a canvas context for text measurement
  const canvas = document.createElement('canvas');
  const canvasContext = canvas.getContext('2d')!;

  // Helper function to set the canvas font
  const setCanvasFont = (size: number) => {
    canvasContext.font = `${fontWeight} ${size}px ${fontFamily}`;
  };

  // Initial font setup
  setCanvasFont(originalFontSize);

  // Define a tolerance for PrinceXML text breaking
  // When the text width is nearly equal to the blockElementParentWidth, PrinceXML may break the text into two lines during HTML parsing.
  // To prevent this, we check if the width difference is less than 5 pixels, allowing for a margin of error.
  // If the difference is smaller than 5 pixels, we reduce the font size by 1px to ensure the text fits on a single line.
  const PRINCE_XML_TOLERANCE = 5;

  // Adjust font size to fit within parent width or reduce to a minimum of 2px
  while (fontSize >= 2) {
    setCanvasFont(fontSize);
    const textWidth = canvasContext.measureText(text).width;
    const widthDifference = Math.abs(textWidth - blockElementParentWidth);

    // Break if the text fits within the parent width and the difference is large enough to prevent issues with PrinceXML
    if (textWidth <= blockElementParentWidth && widthDifference >= PRINCE_XML_TOLERANCE) {
      break;
    }

    fontSize--;
  }

  // Apply the adjusted font size if different from the original
  if (fontSize < originalFontSize) {
    blockElement.style.fontSize = `${Math.floor(fontSize)}px`;
  }

  // Make the parent element visible
  blockElementParent.style.visibility = 'visible';
};
