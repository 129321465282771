import { AccredibleCredential, AccredibleDesign } from '@accredible-frontend-v2/models';
import { AccredibleFontService } from '@accredible-frontend-v2/services/font';
import { AccredibleS3NoIndexHelper } from '@accredible-frontend-v2/utils/s3-no-index-helper';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { StateChange } from 'ng-lazyload-image';

export interface ProcessingData {
  type: 'image' | 'text';
  increment: number;
}

@Component({
  selector: 'accredible-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: [`./certificate.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CertificateComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @Input()
  design: AccredibleDesign;
  @Input()
  partialCredential: Partial<AccredibleCredential>;
  @Input()
  size: 'small' | 'default' = 'default';
  @Input()
  forPrint = false;
  @Input()
  skipImageRendering = false;
  @Input()
  centerLandscape = true;
  @Input()
  centerPortrait = true;
  @Input()
  ariaLabel: string;

  @Output()
  ready = new EventEmitter();

  @ViewChild('certificateInner')
  certificateInner: ElementRef<HTMLElement>;

  backgroundImageUrl: string;
  processingTallies = {
    image: 0,
    text: 0,
  };
  readySent = false;
  originalWidth: number;

  constructor(
    private readonly _hostElement: ElementRef<HTMLElement>,
    private readonly _font: AccredibleFontService,
  ) {}

  processingTracker = (_data: ProcessingData): void => {
    // Do nothing by default
  };

  @HostListener('window:resize', [])
  onWindowResize(): void {
    // Print-mode is not responsive
    if (this._hostElement.nativeElement.classList.contains('print')) {
      return;
    }

    // Read the original dimensions
    const originalWidth = this.certificateInner.nativeElement.offsetWidth;
    const originalHeight = this.certificateInner.nativeElement.offsetHeight;

    if (!originalWidth || !originalHeight) {
      // Nothing to do
      return;
    }

    // Container width
    let containerSize = +this._hostElement.nativeElement.getAttribute('size');
    if (!containerSize) {
      // Get the "natural" size of the container
      this._hostElement.nativeElement.style.width = '';
      this._hostElement.nativeElement.style.height = '';
      this._hostElement.nativeElement.style.margin = '';
      containerSize = this._hostElement.nativeElement.parentElement.offsetWidth;
    }

    // Defaults
    let width: number;
    let height: number;
    let scale: number;
    let marginTopBottom = 0;
    let marginLeftRight = 0;

    // Render as a square? Optional class. Landscape is always rendered square
    const isSquare = this._hostElement.nativeElement.classList.contains('square');

    // Rendering logic
    if (isSquare) {
      if (originalHeight > originalWidth) {
        // Portrait
        scale = containerSize / originalHeight;
        width = originalWidth * scale;
        height = containerSize;
        if (this.centerPortrait) {
          marginLeftRight = (containerSize - width) / 2;
        }
      } else {
        // Landscape
        scale = containerSize / originalWidth;
        width = containerSize;
        height = originalHeight * scale;
        if (this.centerLandscape) {
          marginTopBottom = (containerSize - height) / 2;
        }
      }
    } else {
      // Full-width
      scale = containerSize / originalWidth;
      width = containerSize;
      height = originalHeight * scale;
    }

    // Now render
    this.certificateInner.nativeElement.style.transform = 'scale(' + scale + ')';

    this._hostElement.nativeElement.style.width = width + 'px';
    this._hostElement.nativeElement.style.height = height + 'px';
    this._hostElement.nativeElement.style.margin = marginTopBottom + 'px ' + marginLeftRight + 'px';
  }

  ngOnInit(): void {
    this.backgroundImageUrl = AccredibleS3NoIndexHelper.redirectUrls(this.design.background);
    // If we know there are text blocks that need scaling, assign the number of them,
    // ignoring those text blocks that have an empty model ( i.e. contain no text), to processingTallies.text
    this.processingTallies.text = this.design.blocks.reduce(
      (sum, el): number => (el.scale_to_fit && el.model ? sum + 1 : sum),
      0,
    );

    this._font.loadCertificateDesignFonts(this.design);
    // If someone's listening, track the progress of the child block components
    if (this.ready.observed) {
      this.processingTracker = this._onUpdateProcessing;
    }
  }

  ngAfterViewInit(): void {
    if (!this.design.background) {
      // Spoof and image load to kick off update processing
      this._onUpdateProcessing({ type: 'image', increment: 1 });
      setTimeout(() => {
        this._onUpdateProcessing({ type: 'image', increment: -1 });
      });
    }
  }

  ngAfterViewChecked(): void {
    // Safari's layout rendering behavior differs from other browsers, proving different offset
    // values for the height/width of the certificate inner element when it loads
    // By calling this.onWindowResize() everytime the width changes, we make sure that the certificates are always drawn correctly in Safari
    if (this.originalWidth !== this.certificateInner.nativeElement.offsetWidth) {
      this.originalWidth = this.certificateInner.nativeElement.offsetWidth;
      // Calling this.onWindowResize() is needed not only to fix the Safari issue mentioned above,
      // but also to make sure that ALL certificates are always drawn correctly on first load
      this.onWindowResize();
    }
  }

  loaded(): void {
    this.ready.emit();
  }

  // For the background image
  imageLoadUpdate(event: StateChange): void {
    if (this.ready.observed) {
      switch (event.reason) {
        case 'setup':
          // Inform certificate that there is an image to load
          this._onUpdateProcessing({ type: 'image', increment: 1 });
          break;
        case 'finally':
          // Inform certificate that the image has loaded
          this._onUpdateProcessing({ type: 'image', increment: -1 });
          break;
      }
    }
  }

  private _onUpdateProcessing(data: ProcessingData): void {
    // Only emit ready if not already sent
    if (!this.readySent) {
      // Only track image tallies if not printing
      if (!this.skipImageRendering || data.type === 'text') {
        // Update tally, this will make at least one tally not zero...
        this.processingTallies[data.type] = this.processingTallies[data.type] + data.increment;
      }
      // ... unless everything has finished processing
      if (this.processingTallies.image === 0 && this.processingTallies.text === 0) {
        // ... in which case
        this.ready.emit();
        this.readySent = true;
      }
    }
  }
}
