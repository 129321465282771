import { AccredibleDesign } from '@accredible-frontend-v2/models';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { AccredibleFont, AccredibleFontChecklist, AccredibleFontOptions } from './font.model';
import { ACCREDIBLE_FONTS } from './fonts';

/**
 * To ensure lookups in _loadedFonts don't fail due to varying cases we always set values to lowercase.
 * Because we rely on values directly queried from the DOM, browsers identify some system fonts with lower case.
 * @example
 * `A paragraph styled with Monospace will return monospace when queried.`
 */
const getLoadedFontsLookupKey = (
  family: string,
  weight: number | string,
  style: string,
): string => {
  return String(family + weight + style).toLowerCase();
};

@Injectable({
  providedIn: 'root',
})
export class AccredibleFontService {
  private readonly _host: string;

  private _fonts: { [key: string]: AccredibleFont } = ACCREDIBLE_FONTS;
  // Mechanism for checking if a font has loaded and then doing something if it has
  private _loadedFonts: { [key: string]: boolean } = {};
  private _defaultFont = 'Source Sans Pro';

  private _weights: { [key: number]: string } = {
    100: 'Thin',
    200: 'Extra Light',
    300: 'Light',
    400: 'Normal',
    500: 'Medium',
    600: 'Semi Bold',
    700: 'Bold',
    800: 'Extra Bold',
    900: 'Black',
  };

  private _checklist: AccredibleFontChecklist[] = []; // The list of (font) family, weight, style and actions items for the fonts that are required to have loaded
  private _checkCount = 0; // The number of times left to check that a font has loaded
  private _checkTimeout: ReturnType<typeof setTimeout> = null; // The timeout reference

  constructor(@Inject(DOCUMENT) private _document: Document) {
    this._host = window.location.host;

    this._updateFonts();

    // Assume system fonts are already loaded
    Object.keys(this._fonts).forEach((fontName) => {
      const font = this._fonts[fontName];
      if (font.type === 'system') {
        font.weights.forEach((weight) => {
          const style = typeof weight === 'number' ? 'normal' : 'talic'; // Italic font weights end in an 'i' so adding 'talic' results in 'italic'
          const lookupKey = getLoadedFontsLookupKey(fontName, weight, style);
          this._loadedFonts[lookupKey] = true;
        });
      }
    });
  }

  // Remove quotes from string
  private static _unquote(str: string): string {
    return str.replace(/"/g, '');
  }

  getStatus(fontName: string): AccredibleFont {
    return this._fonts[fontName] || <AccredibleFont>{};
  }

  // Returns all available fonts
  getFonts(fontOptions: AccredibleFontOptions): { [key: string]: AccredibleFont } {
    fontOptions = fontOptions || {};
    const tmpFonts = _.cloneDeep(this._fonts);
    // Unless we explicitly choose not to...
    if (!fontOptions.allOrganizations) {
      // ...remove any fonts that belong to a different organization
      // TODO: Change to Angular 2+ code when this is used in the dashboard
      // let organizationId = 0;
      // if ($injector.has('OrganizationService')) {
      //   const OrganizationService = $injector.get('OrganizationService');
      //   var organization = OrganizationService.getOrganizationImmediate();
      //   if (organization && organization.id) {
      //     organizationId = organization.id;
      //   }
      // }
      // Object.keys(tmpFonts).forEach((fontName) => {
      //   const font = tmpFonts[fontName];
      //   if (font.organization && font.organization !== organizationId) {
      //     delete tmpFonts[fontName];
      //   }
      // });
    }

    // Filter by type?
    if (fontOptions.type) {
      Object.keys(tmpFonts).forEach((fontName) => {
        const font = tmpFonts[fontName];
        if (font.type && font.type !== fontOptions.type) {
          delete tmpFonts[fontName];
        }
      });
    }

    // Include specific, desired fonts
    if (fontOptions.include) {
      tmpFonts[fontOptions.include] = _.cloneDeep(this._fonts[fontOptions.include]);
    }

    // And return
    return tmpFonts;
  }

  // Returns a CSS @import string, ie:
  // @import url('https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900,900italic');
  getCssImportString(fontName: string, fontVariantsArray: (number | string)[]): string | null {
    const font = this._fonts[fontName];
    if (font) {
      if (font.type === 'google') {
        return (
          "@import url('https://fonts.googleapis.com/css?family=" +
          encodeURIComponent(fontName) +
          ':' +
          fontVariantsArray.join(',') +
          "');"
        );
      } else if (font.type === 'custom') {
        let cssImportString = '';
        for (let vl = fontVariantsArray.length, i = 0; i < vl; i++) {
          const fontVariant = fontVariantsArray[i];
          const fontStyle = fontVariantsArray[i].toString().includes('i') ? 'italic' : 'normal';
          const fontWeight = fontVariantsArray[i].toString().replace('i', '');
          cssImportString +=
            '@font-face {\n' +
            `font-family: "${fontName}";\n` +
            `font-weight: ${fontWeight};\n` +
            `font-style: ${fontStyle};\n` +
            `src: url('//${this._host}/assets/fonts/${fontName}/${fontName} - ${fontVariant}.woff2') format('woff2'),\n` +
            `     url('//${this._host}/assets/fonts/${fontName}/${fontName} - ${fontVariant}.woff') format('woff'),\n` +
            `     url('//${this._host}/assets/fonts/${fontName}/${fontName} - ${fontVariant}.ttf')  format('truetype'),\n` +
            `     url('//${this._host}/assets/fonts/${fontName}/${fontName} - ${fontVariant}.svg#svgFontfontName') format('svg'),\n` +
            '}';
          cssImportString += i < vl - 1 ? '\n' : '';
        }
        return cssImportString;
      }
    }

    return null;
  }

  // Returns the weights map
  getWeights(): { [key: number]: string } {
    return this._weights;
  }

  // Returns the nearest weight for a font (defaulting to `400`)
  // ...useful when changing the font to a family that has different available weights
  getNearestWeight(fontName: string, currentWeight: number | string): number {
    if (!this._fonts[fontName]) {
      // Fallback
      fontName = this._defaultFont;
    }

    const availableWeights = this._fonts[fontName].weights;
    let minDistance = 900;
    let closestWeight = 400; // The default `normal` value

    Object.keys(availableWeights).forEach((key) => {
      const weightValue = availableWeights[Number(key)];
      if (Math.abs(Number(weightValue) - +currentWeight) < minDistance) {
        minDistance = Math.abs(Number(weightValue) - +currentWeight);
        closestWeight = Number(weightValue);
      }
    });

    return closestWeight;
  }

  // Check to see if font family has a particular italic weight
  hasItalicWeight(fontFamily: string, fontWeight: number | string): boolean {
    return this._fonts[fontFamily].weights.indexOf(fontWeight + 'i') > -1;
  }

  // Loads a single font, if we haven't already
  loadFont(fontName: string): void {
    let font: AccredibleFont;
    fontName = fontName.trim();

    // Google fonts
    if (
      this._fonts[fontName] &&
      this._fonts[fontName].type === 'google' &&
      !this._fonts[fontName].loaded
    ) {
      font = this._fonts[fontName];
      const headElement = this._document.querySelector('head');
      const linkElement = this._document.createElement('link');
      linkElement.setAttribute('rel', 'stylesheet');
      linkElement.setAttribute(
        'href',
        'https://fonts.googleapis.com/css?family=' +
          fontName.replace(/\s/g, '+') +
          ':' +
          font.weights.join(',') +
          '&display=block',
      );
      headElement.append(linkElement);
      font.loaded = true;
    }

    // Custom fonts
    if (
      this._fonts[fontName] &&
      this._fonts[fontName].type === 'custom' &&
      !this._fonts[fontName].loaded
    ) {
      font = this._fonts[fontName];
      const headElement = this._document.querySelector('head');
      const styleElement = this._document.createElement('style');
      styleElement.setAttribute('type', 'text/css');
      styleElement.innerHTML = this.getCssImportString(fontName, font.weights);
      headElement.append(styleElement);
      font.loaded = true;
    }

    // System fonts
    if (
      this._fonts[fontName] &&
      this._fonts[fontName].type === 'system' &&
      !this._fonts[fontName].loaded
    ) {
      // Just assume it's there
      font = this._fonts[fontName];
      font.loaded = true;
    }
  }

  // Load all fonts used in a design
  loadCertificateDesignFonts(certificateDesign: AccredibleDesign): void {
    if (certificateDesign && certificateDesign.blocks) {
      for (let bl = certificateDesign.blocks.length, i = 0; i < bl; i++) {
        if (certificateDesign.blocks[i].font) {
          this.loadFont(certificateDesign.blocks[i].font);
        }
      }
    }
    // See block comment
    this.loadFont(this._defaultFont);
  }

  whenFontLoaded(family: string, weight: number | string, style: string, action: () => void): void {
    // action cannot be null
    family = AccredibleFontService._unquote(family);
    weight = this._weightAsNumber(weight);
    const lookupKey = getLoadedFontsLookupKey(family, weight, style);
    if (this._loadedFonts[lookupKey]) {
      // Already loaded
      action();
    } else {
      if (style === 'italic' && !this.hasItalicWeight(family, weight)) {
        style = 'normal';
      }

      // Is the font already being checked
      let beingChecked = false;
      this._checklist.forEach((listItem) => {
        if (listItem.family === family && listItem.weight === weight && listItem.style === style) {
          // It is...
          beingChecked = true;
          // Store action
          listItem.actions.push(action);
        }
      });

      if (!beingChecked) {
        // Not being checked, so add it to the list
        this._checklist.push({
          family: family,
          weight: weight,
          style: style,
          actions: [action],
        });
      }

      this._checkCount = 20;
      if (this._checkTimeout) {
        // Cancel any pending check
        clearTimeout(this._checkTimeout);
      }

      this._checkFontsUntilLoaded();
    }
  }

  // Add the "name" as a property
  // Add the "family" as a property (combines the fallback and the fontName)
  // Add the "googleFamily" for each - this is the string that google fonts uses to identify the font
  private _updateFonts(): void {
    Object.keys(this._fonts).forEach((fontName) => {
      const font = this._fonts[fontName];
      font.name = fontName;
      font.family = "'" + fontName + "'" + (font.fallback ? ', ' + font.fallback : '');
      if (font.type === 'google') {
        font.googleFamily =
          fontName.replace(/\s/g, '+') + ':' + font.weights.join(',') + ':latin,latin-ext';
      }
    });
  }

  private _weightAsNumber(weight: number | string): number {
    weight = +weight;

    if (typeof weight === 'number') {
      // Is a number
      return weight;
    } else {
      // Is not a number as a string, i.e. 'Normal', 'Bold', etc
      let weightNumber = 400; // Default to 'Normal'

      Object.keys(this._weights).forEach((key) => {
        const weightValue = this._weights[Number(key)];
        if (weightValue.toLowerCase() === weight) {
          weightNumber = +key;
        }
      });
      return weightNumber;
    }
  }

  // Remove loaded fonts from the checklist, firing the actions as we go
  private _checkFontsLoaded(): void {
    const unloadedChecklist: AccredibleFontChecklist[] = [];

    this._checklist.forEach((listItem) => {
      let fontLoaded = false;
      const iterator = (document as any).fonts.values();
      let fontFace: any;

      while ((fontFace = iterator.next().value)) {
        const listItemWeight = +listItem.weight;
        // The test here uses fontFace.weight == listItemWeight, rather than fontFace.weight === listItemWeight as Chrome returns fontFace.weight as a string of the number not the number itself
        if (
          AccredibleFontService._unquote(fontFace.family) === listItem.family &&
          (+fontFace.weight === listItemWeight ||
            (!isNaN(listItemWeight) &&
              fontFace.weight === this._weights[listItemWeight].toLowerCase())) &&
          fontFace.style === listItem.style &&
          fontFace.status === 'loaded'
        ) {
          fontLoaded = true;
          break;
        }
      }

      if (fontLoaded) {
        const lookupKey = getLoadedFontsLookupKey(listItem.family, listItem.weight, listItem.style);
        this._loadedFonts[lookupKey] = true;
        listItem.actions.forEach((action) => {
          action();
        });
      } else {
        unloadedChecklist.push(listItem);
      }
    });

    if (unloadedChecklist.length === 0) {
      this._checkCount = 0;
    } else {
      this._checkCount--;
    }

    this._checklist = unloadedChecklist;
  }

  // Check the fonts loaded and reschedule if there are any retries (checkCount) left
  private _checkFontsUntilLoaded(): void {
    this._checkFontsLoaded();
    if (this._checkCount > 0) {
      // Schedule the next check for 100 milliseconds time
      this._checkTimeout = setTimeout(this._checkFontsUntilLoaded.bind(this), 100);
    } else if (this._checklist.length > 0) {
      console.warn(
        'Failed to load fonts:',
        this._checklist.map((font) => {
          return font.family + ':' + font.weight + ':' + font.style;
        }),
      );
    }
  }
}
