import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CredentialRendererLoadService } from './credential-renderer-load.service';

export function initApp(appLoadService: CredentialRendererLoadService): () => Promise<boolean> {
  return (): Promise<boolean> => appLoadService.initializeApp();
}

@NgModule({
  imports: [HttpClientModule],
  providers: [
    CredentialRendererLoadService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [CredentialRendererLoadService],
      multi: true,
    },
  ],
})
export class CredentialRendererLoadModule {}
