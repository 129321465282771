import { correctDateForCurrentTimeZone } from '@accredible-frontend-v2/utils/date-helper';
import { DatePipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'localeDate' })
// This pipe is also an injectable in order to be able to use it within a ts file (attribute.service.ts)
@Injectable({
  providedIn: 'root',
})
export class AccredibleLocaleDatePipe extends DatePipe implements PipeTransform {
  transform(value: string, format: string, languageCode: string, timezone?: string): any {
    // Check that value is in a valid date format
    if (new Date(value).getTime()) {
      if (!timezone) {
        value = correctDateForCurrentTimeZone(value).toString();
      }
      return super.transform(value, format, '', languageCode);
    }
    return value;
  }
}
