<div [attr.role]="ariaLabel ? 'img' : null"
  [attr.aria-label]="ariaLabel || null ">
  <div #svgContainer
    class="svg-container"
    aria-hidden="true"
    inert
    [innerHTML]="badgeSVG"
    [style.width]="size"
    [style.height]="size">
  </div>
</div>
