import { AccredibleFontService } from '@accredible-frontend-v2/services/font';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'toFontFamily' })
export class ToFontFamilyPipe implements PipeTransform {
  constructor(private readonly _font: AccredibleFontService) {}

  transform(fontName: string): string {
    const fonts = this._font.getFonts({ allOrganizations: true });

    if (fonts[fontName]) {
      return fonts[fontName].family;
    }

    return fontName;
  }
}
