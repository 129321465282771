import { AccredibleCredential, AccredibleGroup } from '@accredible-frontend-v2/models';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'accredible-credential-appearance',
  templateUrl: './credential-appearance.component.html',
  styleUrls: [`./credential-appearance.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CredentialAppearanceComponent implements OnChanges {
  @Input()
  group: AccredibleGroup;
  @Input()
  partialCredential: Partial<AccredibleCredential>;
  @Input()
  size: 'small' | 'default' = 'default';
  @Input()
  centerLandscape = true;
  @Input()
  centerPortrait = true;
  @Input()
  showLabel = true;
  @Output()
  ready = new EventEmitter();

  badgeReady = false;
  certificateReady = false;

  constructor(private readonly _cdr: ChangeDetectorRef) {}

  /**
   * @author Andrew
   *
   * This onChanges handler triggers the appearance to be redrawn when the group assigned to it changes.
   * Without this the appearance is only partially (incorrectly) redrawn when the group changes.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.group) {
      if (changes.group.isFirstChange()) {
        // These two ifs are used because of the credential renderer. If there is no badge design, for example,
        // that means the badge design is ready. Same for the certificate. That means that when both designs are
        // considered ready, we can tell the credential renderer that the whole group appearance is ready.
        if (!changes.group.currentValue?.badge_design) {
          this.badgeReady = true;
        }
        if (!changes.group.currentValue?.certificate_design) {
          this.certificateReady = true;
        }
      } else {
        const cachedGroup = this.group;
        this.group = null;

        requestAnimationFrame(() => {
          this.group = cachedGroup;
          this._cdr.markForCheck();
        });
      }
    }
  }

  /**
   * Verifies if both badge and certificate designs are ready, and notifies the credential renderer via an emit
   */
  handleDesignReady(designType: string): void {
    if (this.ready.observed) {
      if (designType === 'certificate') {
        this.certificateReady = true;
      }
      if (designType === 'badge') {
        this.badgeReady = true;
      }

      if (this.certificateReady && this.badgeReady) {
        this.ready.emit();
      }
    }
  }
}
