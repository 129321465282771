import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'convertFontSizeToEm' })
export class ConvertFontSizeToEmPipe implements PipeTransform {
  transform(value: string): string {
    if (value && value.indexOf('px') !== -1) {
      return value.replace('px', 'em');
    }

    return value;
  }
}
