<div *ngIf="!partialCredential"
  class="block-qrcode">
  <svg style="width: 100%"
    aria-hidden="true"
    viewBox="0 0 36 36"
    focusable="false">
    <rect width="100%"
      height="100%"
      [attr.fill]="block.background_color"/>
    <text x="0"
      y="37"
      font-family="acc-icon-font"
      font-size="36px"
      [attr.fill]="block.foreground_color">
      &#xE032;
    </text>
  </svg>
</div>

<div *ngIf="partialCredential?.url"
  class="block-qrcode"
  aria-hidden="true">
  <qrcode-svg role="img"
    [value]="partialCredential.url"
    [size]="block.pixel_width + 'px'"
    [borderSize]="4"
    [foregroundColor]="block.foreground_color"
    [backgroundColor]="block.background_color"></qrcode-svg>
</div>
