import { AccredibleAttributeServiceModule } from '@accredible-frontend-v2/services/attribute';
import { AccredibleSvgProcessorServiceModule } from '@accredible-frontend-v2/services/svg-processor';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BadgeComponent } from './components/badge.component';

@NgModule({
  imports: [CommonModule, AccredibleAttributeServiceModule, AccredibleSvgProcessorServiceModule],
  exports: [BadgeComponent],
  declarations: [BadgeComponent],
  providers: [],
})
export class AccredibleBadgeModule {}
