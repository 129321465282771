import { AccredibleCredential, AccredibleDesignBlock } from '@accredible-frontend-v2/models';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ProcessingData } from '../certificate/certificate.component';

@Component({
  selector: 'accredible-block',
  templateUrl: './block.component.html',
  styleUrls: [`./block.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockComponent {
  @Input()
  block: AccredibleDesignBlock;
  @Input()
  partialCredential: Partial<AccredibleCredential>;

  @Output()
  updateProcessing = new EventEmitter<ProcessingData>();

  onUpdateProcessing(data: ProcessingData): void {
    this.updateProcessing.emit(data);
  }
}
