import { AccredibleCredential, AccredibleDesignBlock } from '@accredible-frontend-v2/models';
import { AccredibleAttributeService } from '@accredible-frontend-v2/services/attribute';
import { AccredibleS3NoIndexHelper } from '@accredible-frontend-v2/utils/s3-no-index-helper';
import { AccredibleUrlHelper } from '@accredible-frontend-v2/utils/url-helper';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { StateChange } from 'ng-lazyload-image';
import { ProcessingData } from '../certificate/certificate.component';

@Component({
  selector: 'accredible-block-image',
  templateUrl: './block-image.component.html',
  styleUrls: [`./block-image.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockImageComponent implements OnInit {
  @Input()
  block: AccredibleDesignBlock;
  @Input()
  partialCredential: Partial<AccredibleCredential>;

  @Output()
  updateProcessing = new EventEmitter<ProcessingData>();

  imgSrc: string;
  isImageUrlValid: boolean;

  constructor(private readonly _attribute: AccredibleAttributeService) {}

  ngOnInit(): void {
    if (this.block.custom_image_attribute) {
      if (this.partialCredential) {
        this.imgSrc = AccredibleS3NoIndexHelper.redirectUrls(
          this._attribute.replaceAttributes(
            this.block.custom_image_attribute,
            this.partialCredential,
          ),
        );
      }
    } else {
      this.imgSrc = AccredibleS3NoIndexHelper.redirectUrls(this.block.image_url);
    }

    this.isImageUrlValid = AccredibleUrlHelper.isValidUrl(this.imgSrc);
  }

  imageLoadUpdate(event: StateChange): void {
    switch (event.reason) {
      case 'setup':
        // Inform certificate that there is an image to load
        this.updateProcessing.emit({ type: 'image', increment: 1 });
        break;
      case 'finally':
        // Inform certificate that the image has loaded
        this.updateProcessing.emit({ type: 'image', increment: -1 });
        break;
    }
  }
}
