export class AccredibleS3NoIndexHelper {
  static redirectUrls(input: string): string {
    if (input) {
      let output = input.replace(/s3\.amazonaws\.com\/accredible-api-templates|s3\.us-east-1\.amazonaws\.com\/accredible-api-templates|accredible-api-templates\.s3\.amazonaws\.com|accredible-api-templates\.s3\.us-east-1\.amazonaws\.com/g, 'templates.images.credential.net');
      output = output.replace(/s3\.amazonaws\.com\/accredible-api-templates-eu|s3\.eu-central-1\.amazonaws\.com\/accredible-api-templates-eu|accredible-api-templates-eu\.s3\.amazonaws\.com|accredible-api-templates-eu\.s3\.eu-central-1\.amazonaws\.com/g, 'templates-eu.images.credential.net');
      output = output.replace(/s3\.amazonaws\.com\/accredible_temp_credential_images|s3\.us-east-1\.amazonaws\.com\/accredible_temp_credential_images|accredible_temp_credential_images\.s3\.amazonaws\.com|accredible_temp_credential_images\.s3\.us-east-1\.amazonaws\.com/g, 'temp.images.credential.net');
      output = output.replace(/s3\.amazonaws\.com\/accredible-temp-credential-images-eu|s3\.eu-central-1\.amazonaws\.com\/accredible-temp-credential-images-eu|accredible-temp-credential-images-eu\.s3\.amazonaws\.com|accredible-temp-credential-images-eu\.s3\.eu-central-1\.amazonaws\.com/g, 'temp-eu.images.credential.net');
      output = output.replace(/s3\.amazonaws\.com\/accredible-api-badge|s3\.us-east-1\.amazonaws\.com\/accredible-api-badge|accredible-api-badge\.s3\.amazonaws\.com|accredible-api-badge\.s3\.us-east-1\.amazonaws\.com/g, 'badges.images.credential.net');
      return output;
    } else {
      // Hello
      return input;
    }
  }
}
