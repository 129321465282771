import { AccredibleGeoCoordinates, AccredibleGeoViewport } from './geolocation.model';
import { AccredibleMfaSettings } from './mfa.model';
import { AccredibleSocialMedia } from './social-media.model';

export interface AccredibleProfile {
  id: string;
  name: string;
  skills: string[];
  bio: string;
  location: AccredibleProfileLocation;
  avatar_url: string;
  social_media: AccredibleSocialMedia;
  course_recommendations_enabled: boolean;

  // Used in Accounts and Spotlight
  encoded_id: number;
  visibility: AccredibleProfileVisibility;
  user_work_experiences: AccredibleProfileWorkExperience[];
  availability: AccredibleProfileAvailability[];

  mfa_settings: AccredibleMfaSettings;
}

export interface AccredibleProfileLocation {
  place_id: string;
  name: string;
  geo: AccredibleProfileLocationGeo;
}

interface AccredibleProfileLocationGeo extends AccredibleGeoCoordinates {
  viewport: AccredibleGeoViewport;
}

export enum AccredibleProfileAvailability {
  FULL_TIME = 'full-time',
  PART_TIME = 'part-time',
  CONTRACT = 'contract',
}

export interface AccredibleProfileWorkExperience {
  id: string;
  role: string;
  company_name: string;
  company_url: string;
  start_date: string;
  end_date: string;
  description: string;

  // This is used to inform the BE if a work experience is to be deleted
  _destroy?: boolean;

  // FE helper props
  isNewWorkExperience?: boolean;
}

export interface AccredibleProfileVisibility {
  [key: number]: boolean;
}
