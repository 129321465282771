<div class="version">{{ version }}</div>

<div *ngIf="credential"
  class="credential-wrapper"
  [style.overflow]="credential.imageType === 'certificate' && forPrint === true ? 'visible' : 'hidden'"
  [class]="{
    'print-quality': credential.imageType === 'certificate' && forPrint === true,
    'a4':            credential.imageType === 'certificate' && design.size === 'a4',
    'a8':            credential.imageType === 'certificate' && design.size === 'a8',
    'us-letter':     credential.imageType === 'certificate' && design.size === 'us-letter',
    'landscape':     credential.imageType === 'certificate' && design.orientation === 'landscape',
    'portrait':      credential.imageType === 'certificate' && design.orientation === 'portrait'
  }">

  <ng-container [ngSwitch]="credential.imageType">
    <accredible-certificate *ngSwitchCase="'certificate'"
      [design]="design"
      [partialCredential]="credential"
      [forPrint]="forPrint"
      [skipImageRendering]="skipImageRendering"
      (ready)="renderingComplete()">
    </accredible-certificate>

    <accredible-badge *ngSwitchCase="'badge'"
      size="400px"
      [style.display]="'inline-block'"
      [design]="design"
      [partialCredential]="credential"
      (ready)="renderingComplete()">
    </accredible-badge>

    <div *ngSwitchCase="'group_appearance'"
      class="credential-appearance-wrapper">
      <accredible-credential-appearance
        [partialCredential]="credential"
        [group]="credential.group"
        [showLabel]="false"
        (ready)="renderingComplete()">
      </accredible-credential-appearance>
    </div>

  </ng-container>


  <!-- Only show the expiry date if the certificate PDF is being downloaded of an expired credential -->
  <div *ngIf="credential.expired"
    class="certificate-expired-notice">
    <svg xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 72 72">
      <path d="M36 8C51.4 8 64 20.6 64 36 64 51.4 51.4 64 36 64 20.6 64 8 51.4 8 36 8 20.6 20.6 8 36 8M36 0C16.1 0 0 16.1 0 36 0 55.9 16.1 72 36 72 55.9 72 72 55.9 72 36 72 16.1 55.9 0 36 0L36 0z"></path>
      <polygon points="50.9 48.1 33.4 37.2 33.4 17.4 38.4 17.4 38.4 34.4 53.5 43.9 "></polygon>
    </svg>
    <strong>Expired on:</strong> {{ credential.expired_on }}
  </div>
</div>
