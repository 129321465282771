import { environment } from '@accredible-frontend-v2/envs';
import { AccredibleDesign, AccredibleGroup } from '@accredible-frontend-v2/models';
import { Component, OnInit } from '@angular/core';
import { ImageableCredential } from './models/renderer.models';
import { RendererService } from './services/renderer.service';

@Component({
  selector: 'cr-root',
  templateUrl: './credential-renderer.container.html',
  styleUrls: ['./credential-renderer.container.scss'],
})
export class CredentialRendererContainer implements OnInit {
  requestType: 'credential' | 'design' | 'group';
  credential: ImageableCredential;
  design: AccredibleDesign;
  forPrint: boolean;
  skipImageRendering: boolean;
  group: AccredibleGroup;
  readonly version = environment.version;

  private _queryParamsObj: { [key: string]: any };

  constructor(private readonly _renderer: RendererService) {}

  static _getUtf8Bytes(str: string): Uint8Array {
    return new Uint8Array([...unescape(encodeURIComponent(str))].map((c) => c.charCodeAt(0)));
  }

  ngOnInit(): void {
    this._queryParamsObj = this._mapValidUrlQueryParams(document.location.href);

    if (this._isQueryValid(this._queryParamsObj)) {
      this.requestType = this._getRequestType(this._queryParamsObj);
      this.skipImageRendering = this._setSkipImageRendering(this._queryParamsObj);

      this._loadCredentialOrDesignOrGroup(this.requestType, this._queryParamsObj);
    } else {
      console.log('err: invalid query parameters');
    }
  }

  //*** Public methods called from template ***//

  renderingComplete(): void {
    console.log('imageIsRendered');
  }

  //*** Url processing methods ***//

  _mapValidUrlQueryParams(href: string): { [key: string]: any } {
    const url = new URL(href);

    const validParams = [
      'data',
      'signature',
      'design',
      'key',
      'credential',
      'design_kind',
      'mode',
      'group',
    ];
    const output = {};

    validParams.forEach((param) => {
      const paramValue = url.searchParams.get(param);
      if (paramValue) {
        output[param] = paramValue;
      }
    });
    return output;
  }

  //*** Query processing methods ***//

  _isQueryValid(queryObj: { [key: string]: any }): boolean {
    if (queryObj['credential']) {
      if (
        queryObj['design_kind'] === 'badge' ||
        queryObj['design_kind'] === 'certificate' ||
        queryObj['design_kind'] === 'group_appearance'
      ) {
        return true;
      } else {
        console.log('err: invalid query design_kind');
        return false;
      }
    } else if (queryObj['data'] && queryObj['signature']) {
      return true;
    } else if (queryObj['group']) {
      return true;
    } else {
      console.log('err: Check query string');
      return false;
    }
  }

  _getRequestType(queryObj: { [key: string]: any }): 'credential' | 'design' | 'group' {
    if (queryObj['group']) {
      return 'group';
    }
    return queryObj['credential'] ? 'credential' : 'design';
  }

  _setSkipImageRendering(queryObj: { [key: string]: any }): boolean {
    return !!queryObj['credential'] && queryObj['design_kind'] === 'certificate';
  }

  //*** Design signature and data validation methods ***//

  _getValidPartialCredential(
    queryData: string,
    signature: string,
  ): Promise<Partial<ImageableCredential>> {
    const keyBytes = CredentialRendererContainer._getUtf8Bytes('g6DxwGSeMZGlaYXeb0A3S7zYK585ko');
    return crypto.subtle
      .importKey('raw', keyBytes, { name: 'HMAC', hash: 'SHA-256' }, true, ['sign'])
      .then((key) => {
        return crypto.subtle.sign(
          'HMAC',
          key,
          CredentialRendererContainer._getUtf8Bytes(queryData),
        );
      })
      .then((signatureBytes) => {
        const calculatedSignature = [...new Uint8Array(signatureBytes)]
          .map((b: number) => b.toString(16).padStart(2, '0'))
          .join('');
        return this._validateSignatureAndOutputPartialCredential(
          calculatedSignature,
          signature,
          queryData,
        );
      });
  }

  _validateSignatureAndOutputPartialCredential(
    genSig: string,
    urlSig: string,
    queryData: string,
  ): Partial<ImageableCredential> {
    if (genSig === urlSig) {
      return this._validateJSON(queryData);
    } else {
      console.log('InvalidSignature');
      return null;
    }
  }

  _validateJSON(queryData: string): Partial<ImageableCredential> {
    try {
      const partialCredential = JSON.parse(queryData);
      if (partialCredential.design) {
        delete partialCredential.design;
      }
      if (partialCredential.env) {
        delete partialCredential.env;
      }
      return partialCredential;
    } catch (err) {
      console.log('InvalidData');
      return null;
    }
  }

  //*** Fetching data from API methods ***//

  _loadCredentialOrDesignOrGroup(
    requestType: 'credential' | 'design' | 'group',
    queryParamsObj: { [key: string]: any },
  ): void {
    switch (requestType) {
      // If we are loading a design
      case 'design':
        const queryData = queryParamsObj['data'];
        const signature = queryParamsObj['signature'];
        this._getValidPartialCredential(queryData, signature).then(
          (partialCredential: ImageableCredential) => {
            if (partialCredential) {
              const designId = queryParamsObj['design'];
              this._renderer
                .loadDesign(designId, partialCredential)
                .subscribe((credential: ImageableCredential) => {
                  this._assignImageableCredential(credential, queryParamsObj);
                });
            } else {
              console.log('InvalidData');
            }
          },
        );
        break;
      case 'credential':
        // If we are loading a credential
        const credentialId = queryParamsObj['credential'];
        const imageType = queryParamsObj['design_kind'];
        const key = queryParamsObj['key'];
        this._renderer
          .loadCredential(credentialId, imageType, key)
          .subscribe((credential: ImageableCredential) => {
            this._assignImageableCredential(credential, queryParamsObj);
          });
        break;
      case 'group':
        const groupId = queryParamsObj['group'];
        const designKind = queryParamsObj['design_kind'];
        this._renderer
          .loadGroup(groupId, designKind)
          .subscribe((credential: ImageableCredential) => {
            this._assignImageableCredential(credential, queryParamsObj);
          });
        break;
    }
  }

  //*** Processing API data and assigning for template methods ***//

  _assignImageableCredential(
    credential: ImageableCredential,
    queryParamsObj: { [key: string]: any },
  ): void {
    // Certificates could need HTML tidying and may be printed
    if (credential.imageType === 'certificate') {
      this.forPrint = this._printingIsRequired(queryParamsObj);
      if (this.skipImageRendering) {
        this._injectBaseTag();
      }
      this.design = credential.group.certificate_design;
    } else if (credential.imageType === 'badge') {
      this.design = credential.group.badge_design;
    }
    this.credential = credential;
  }

  //*** Utility methods ***//

  _printingIsRequired(queryObj: { [key: string]: any }): boolean {
    return queryObj['mode'] === 'print';
  }

  // Injects a <base> tag so that when the rendered DOM is passed through PrinceXML we maintain the relative URLs etc.
  _injectBaseTag(): void {
    const att = document.createAttribute('href');
    att.value = window.location.origin;
    document.head.appendChild(document.createElement('base'));
    document.getElementsByTagName('base')[0].setAttributeNode(att);
  }
}
