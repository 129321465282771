import { AccredibleCredential, AccredibleDesign } from '@accredible-frontend-v2/models';
import { AccredibleAttributeService } from '@accredible-frontend-v2/services/attribute';
import { AccredibleFontService } from '@accredible-frontend-v2/services/font';
import { AccredibleSvgProcessorService } from '@accredible-frontend-v2/services/svg-processor';
import { AccredibleS3NoIndexHelper } from '@accredible-frontend-v2/utils/s3-no-index-helper';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { isValidForeignObject } from './badge.helper';

/**
 * TODO(Munro): Lets refactor this into a shared libs enum as it can contain all the Window/User Events
 * that will be re-used throughout multiple files.
 */
enum WindowEvent {
  LOAD = 'load',
}

export enum Element {
  FOREIGN_OBJECT = 'foreignObject',
}

// TODO(Munro): Lets refactor this into a shared libs enum as it can contain all relevant CSS styles
enum Style {
  NORMAL = 'normal',
}

@Component({
  selector: 'accredible-badge',
  templateUrl: './badge.component.html',
  styleUrls: [`./badge.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent implements AfterViewInit, OnInit {
  @Input()
  design: AccredibleDesign;
  @Input()
  size = '100%';
  @Input()
  ariaLabel: string;

  @Output()
  ready = new EventEmitter();

  @ViewChild('svgContainer')
  svgContainer: ElementRef<HTMLElement>;

  badgeSVG: SafeHtml;
  canRedraw = false;

  /**
   * This is a stringed version of an SVG element provided by this.design.svg
   * that can contain several SVG elements such images, text, paths etc.
   */
  private _svgString: string | null;
  private _abstractedImageUrls: string[] = [];
  private _abstractedImagesToLoad: number;
  private _imagesReady = false;
  private _textReady = false;

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _sanitizer: DomSanitizer,
    private readonly _font: AccredibleFontService,
    private readonly _svgProcessor: AccredibleSvgProcessorService,
    private readonly _attribute: AccredibleAttributeService,
  ) {}

  private _partialCredential: Partial<AccredibleCredential>;

  get partialCredential(): Partial<AccredibleCredential> {
    return this._partialCredential;
  }

  @Input()
  set partialCredential(value: Partial<AccredibleCredential>) {
    this._partialCredential = value;

    if (this.canRedraw) {
      this._buildSvgElement();
      requestAnimationFrame(() => this._processText());
    }
  }

  ngOnInit(): void {
    this._buildSvgElement();
  }

  ngAfterViewInit(): void {
    this._processText();
  }

  private _buildSvgElement(): void {
    this._svgString =
      this.design && this.design.svg
        ? AccredibleS3NoIndexHelper.redirectUrls(this.design.svg)
        : null;

    if (!this._svgString) {
      return;
    }

    if (this.partialCredential) {
      // Replace attributes with data if available
      this._svgString = this._attribute.replaceAttributes(
        this._svgString,
        this.partialCredential,
        this._getDateFormatFromSVGString(),
        true, // To avoid text scaling issues, all HTML attribute data is converted to plain text
      );

      // Here we replace every foreignObject that's a custom image attribute with an image element
      this._addCustomImages();
      this._addQRCode();
    }

    // If required, parallel load all the images in the SVG and record on the badge when they have all loaded
    // This is used to inform credential renderer (standalone.credential.net) that the badge is ready
    if (this.ready.observed) {
      this._abstractImageUrlsFromSVGString();
    }

    if (this._abstractedImageUrls.length > 0) {
      this._addImageLoadHandlers();
    } else {
      this._imagesAreReady();
    }

    this._sanitizeAndTrustHtml();
  }

  /**
   * The following function will use regex to break apart the _SvgString into various strings
   * stored on array in order to abstract out the xlink:href attributes from it
   * and store those values in the third index of an array.
   *
   * The while loop will repeat this process until there is no more _SvgString to process,
   * an example of when this process will repeat is in situations where a badge contains several
   * image files.
   */
  private _abstractImageUrlsFromSVGString(): void {
    // Find all the image URLs within the badge XML
    const regex = /<image (.*?)(xlink:href=")(.*?)(?=")/gi;
    const abstractedImageUrls = [];
    let url: RegExpExecArray;

    while ((url = regex.exec(this._svgString))) {
      abstractedImageUrls.push(url[3]);
    }

    this._abstractedImageUrls = abstractedImageUrls;
  }

  private _getDateFormatFromSVGString(): string {
    const regex = /<svg (.*?)(data-date-format=")(.*?)(?=")/i;
    const dateFormat = regex.exec(this._svgString);

    return dateFormat ? dateFormat[3] || 'longDate' : 'longDate';
  }

  private _addImageLoadHandlers(): void {
    this._abstractedImagesToLoad = this._abstractedImageUrls.length;
    for (const imageSource of this._abstractedImageUrls) {
      const img = new Image();
      img.addEventListener(WindowEvent.LOAD, this._loadHandler.bind(this), false);
      img.src = imageSource;
    }
  }

  /**
   * As an image is loaded this method will be called and remove the _loadHandler from its event
   * listeners as it's no longer required.
   *
   * Each time it successfully invokes it will increment the imagesLoaded value, once that matches the
   * _abstractedImageUrls.length we can safely infer that all the images have been loaded and
   * invoke the _imagesAreReady()
   */
  private _loadHandler(event: Event): void {
    event.target.removeEventListener(WindowEvent.LOAD, this._loadHandler.bind(this), false);
    this._abstractedImagesToLoad--;
    if (this._abstractedImagesToLoad === 0) {
      this._imagesAreReady();
    }
  }

  // TODO(Salim): improve this to avoid multiple calls. This might need rewriting the component
  private _sanitizeAndTrustHtml(): void {
    this._svgString = this._svgProcessor.sanitize(this._svgString, this._setCredentialName());
    this._svgString = this._svgProcessor.replaceIds(this._svgString);
    this.badgeSVG = this._sanitizer.bypassSecurityTrustHtml(this._svgString);
  }

  private _setCredentialName(): string | null {
    if (!this.partialCredential) {
      return null;
    }

    const { name, group } = this.partialCredential;
    return name || group?.course_name || null;
  }

  /**
   * This function will only be invoked if the badge contains text elements which are defined
   * by the backend with foreignObject selector.
   */
  private _processText(): void {
    let textNodes = Array.from(
      this.svgContainer.nativeElement.querySelectorAll(Element.FOREIGN_OBJECT),
    );
    // Filter out any text nodes that are empty (i.e. have no text) and are not valid as these should not trigger the font to load
    textNodes = textNodes.filter(
      (foreignObjectElement) =>
        foreignObjectElement.children[0].textContent && isValidForeignObject(foreignObjectElement),
    );

    if (textNodes.length === 0) {
      this._textIsReady();
      this.canRedraw = true;
      return;
    }

    this._loadAndProcessFonts(textNodes);
    this.canRedraw = true;
  }

  private _loadAndProcessFonts(textNodes: SVGForeignObjectElement[]): void {
    let fontsToLoadAndProcess = textNodes.length;

    for (const textNode of textNodes) {
      // We don't wait for the fonts if there is no text content on the node
      if (textNode.firstChild.textContent) {
        const style = textNode.style;
        this._font.whenFontLoaded(
          style.fontFamily,
          style.fontWeight,
          style.fontStyle || Style.NORMAL,
          () => {
            this._svgProcessor.shrinkTextBoxContentToFit(textNode, this.design.id);
            this._svgProcessor.verticalAlignTextBoxContent(textNode);
            fontsToLoadAndProcess--;
            if (fontsToLoadAndProcess === 0) {
              this._textIsReady();
            }
          },
        );
      } else {
        fontsToLoadAndProcess--;
        if (fontsToLoadAndProcess === 0) {
          this._textIsReady();
        }
      }
    }
  }

  private _imagesAreReady(): void {
    this._imagesReady = true;
    this._onReady();
  }

  private _textIsReady(): void {
    this._textReady = true;
    this._onReady();
  }

  private _onReady(): void {
    if (this._imagesReady && this._textReady) {
      this.ready.emit();
    }
  }

  private _addQRCode(): void {
    this._svgString = this._svgProcessor.replaceQRCode(this._svgString, this.partialCredential.url);
  }

  private _addCustomImages(): void {
    this._svgString = this._svgProcessor.addCustomImages(this._svgString);
  }
}
