<div class="certificate"
  [ngClass]="[design.size, size, design.orientation]"
  [class.bg-bleed]="design.background_bleeds && forPrint"
  [class.show-bleed]="forPrint"
  [attr.role]="ariaLabel ? 'img' : null"
  [attr.aria-label]="ariaLabel || null">

  <div #certificateInner
    class="certificate-inner"
    aria-hidden="true"
    inert>

    <!-- Background Image -->
    <!-- NOTE: Need src attribute for the standalone credential renderer to work -->
    @if (backgroundImageUrl) {
      <div class="certificate-bg">
        <img alt=""
          [src]="backgroundImageUrl"
          [lazyLoad]="backgroundImageUrl"
          (onStateChange)="imageLoadUpdate($event)">
      </div>
    }

    <!-- Blocks -->
    @for (block of design.blocks; track index; let index = $index) {
      <accredible-block [block]="block"
        [partialCredential]="partialCredential"
        (updateProcessing)="processingTracker($event)">
      </accredible-block>
    }
  </div>
</div>
