import { AccredibleBadgeModule } from '@accredible-frontend-v2/components/badge';
import { AccredibleCertificateModule } from '@accredible-frontend-v2/components/certificate';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { CredentialAppearanceComponent } from './credential-appearance.component';

@NgModule({
  imports: [CommonModule, TranslocoModule, AccredibleBadgeModule, AccredibleCertificateModule],
  exports: [CredentialAppearanceComponent],
  declarations: [CredentialAppearanceComponent],
  providers: [],
})
export class AccredibleCredentialAppearanceComponentModule {}
