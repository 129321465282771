// @formatter:off (WebStorm ignore)
// prettier-ignore
export const DEFAULT_ATTRIBUTES = [
  // Issuer Defaults
  { parent: 'issuer', key: 'name', type: 'text' },

  // Group Defaults
  { parent: 'group', key: 'course_name', type: 'text' },
  { parent: 'group', key: 'course_description',  type: 'text' },

  // Credential Defaults
  { parent: 'credential', key: 'id', type: 'text', help: 'The Accredible ID of the Credential' },
  { parent: 'credential', key: 'license_id', type: 'text', help: 'The Unique ID your organization uses to reference the Credential' },
  { parent: 'credential', key: 'grade', type: 'text' },
  { parent: 'credential', key: 'url', type: 'text' },
  { parent: 'credential', key: 'uuid', type: 'text' },
  { parent: 'credential', key: 'issued_on', type: 'date' },
  { parent: 'credential', key: 'expired_on', type: 'date' },

  // Recipient Defaults
  { parent: 'recipient', key: 'id', type: 'text', help: 'The Unique ID your organization uses to reference the Recipient' },
  { parent: 'recipient', key: 'name', type: 'text' },
  { parent: 'recipient', key: 'email', type: 'email' },

  // These will likely be useful for sending email logic, but are more confusing than not on screens like the design editor
  // { parent: 'credential', key: 'private', type: 'boolean' },
  // { parent: 'credential', key: 'complete', type: 'boolean' },
  // { parent: 'credential', key: 'allow_supplemental_evidence', type: 'boolean' },
];
