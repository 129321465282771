export const capitalizeText = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const isStringHtml = (text: string): boolean => {
  return new RegExp(/<\/?[a-z][\s\S]*>/i).test(text);
};

export const ensureAtSymbol = (username: string): string => {
  return username.startsWith('@') ? username : `@${username}`;
};
