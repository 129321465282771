import { environment } from '@accredible-frontend-v2/envs';
import { enableProdMode, ViewEncapsulation } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CredentialRendererModule } from './app/core/credential-renderer.module';

if (environment.type === 'production') {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(CredentialRendererModule, {
    defaultEncapsulation: ViewEncapsulation.Emulated,
  })
  .catch((err) => console.error(err));
