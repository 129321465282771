import { AccredibleCredential, AccredibleDesignBlock } from '@accredible-frontend-v2/models';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'accredible-qrcode',
  templateUrl: './block-qrcode.component.html',
  styleUrls: [`./block-qrcode.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockQrcodeComponent {
  @Input()
  block: AccredibleDesignBlock;
  @Input()
  partialCredential: Partial<AccredibleCredential>;
}
