import { AccredibleAttributeServiceModule } from '@accredible-frontend-v2/services/attribute';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QrcodeSvgModule } from '@larscom/ng-qrcode-svg';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BlockImageComponent } from './components/block-image/block-image.component';
import { BlockQrcodeComponent } from './components/block-qrcode/block-qrcode.component';
import { BlockTextComponent } from './components/block-text/block-text.component';
import { BlockComponent } from './components/block/block.component';
import { CertificateComponent } from './components/certificate/certificate.component';
import { ConvertFontSizeToEmPipe } from './pipes/convert-font-size-to-em.pipe';
import { ToFontFamilyPipe } from './pipes/to-font-family.pipe';

const PIPES = [ConvertFontSizeToEmPipe, ToFontFamilyPipe];

@NgModule({
  imports: [CommonModule, AccredibleAttributeServiceModule, LazyLoadImageModule, QrcodeSvgModule],
  exports: [CertificateComponent],
  declarations: [
    CertificateComponent,
    BlockComponent,
    BlockImageComponent,
    BlockTextComponent,
    BlockQrcodeComponent,
    ...PIPES,
  ],
  providers: [],
})
export class AccredibleCertificateModule {}
