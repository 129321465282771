import {
  getAvailableLanguages,
  registerSupportedLocalesData,
} from '@accredible-frontend-v2/services/language';
import { Injectable } from '@angular/core';

@Injectable()
export class CredentialRendererLoadService {
  constructor() {}

  initializeApp(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      // We can do pre-app initialization here
      registerSupportedLocalesData(getAvailableLanguages());
      resolve(true);
    });
  }
}
