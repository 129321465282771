export const isValidForeignObject = (foreignObject: SVGForeignObjectElement): boolean => {
  // A foreignObject should...
  const children = foreignObject.children;
  // ... only contain one child element
  if (children.length !== 1) {
    console.error('Malformed Badge: foreignObject does not contain exactly one child element');
    return false;
  }
  // ... which should not contain any other foreignObjects
  const containedForeignObjectCount = children[0].querySelectorAll(
    'foreignObject foreignobject',
  ).length;
  if (containedForeignObjectCount > 0) {
    console.error(
      `Malformed Badge: foreignObject contains ${containedForeignObjectCount} other foreignObject(s)`,
    );
    return false;
  }
  return true;
};
