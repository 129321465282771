<ng-container *transloco="let t; read: 'credential-appearance'">
  <ng-container *ngIf="!!group">
    <div *ngIf="group.certificate_design && group.badge_design; else certificateTemplate"
      class="appearance"
      [attr.role]="showLabel ? 'img' : null"
      [attr.aria-label]="showLabel ? t('aria_certificate_badge', { courseName: group.course_name }) : null"
      [attr.aria-hidden]="!showLabel">
      <div class="certificate-wrapper"
        [class.primary-design]="!group.primary_design_id || group.certificate_design.id === group.primary_design_id"
        [ngClass]="group.certificate_design.orientation">
        <accredible-certificate class="certificate square"
          aria-hidden="true"
          [design]="group.certificate_design"
          [partialCredential]="partialCredential"
          [size]="size"
          (ready)="handleDesignReady('certificate')">
        </accredible-certificate>
      </div>

      <accredible-badge class="badge"
        aria-hidden="true"
        [class.primary-design]="group.badge_design.id === group.primary_design_id"
        [design]="group.badge_design"
        [partialCredential]="partialCredential"
        (ready)="handleDesignReady('badge')">
      </accredible-badge>
    </div>
  </ng-container>

  <ng-template #certificateTemplate>
    <accredible-certificate *ngIf="group.certificate_design; else badgeTemplate"
      class="certificate square"
      [design]="group.certificate_design"
      [partialCredential]="partialCredential"
      [centerLandscape]="centerLandscape"
      [centerPortrait]="centerPortrait"
      [attr.aria-label]="showLabel ? t('aria_certificate', { courseName: group.course_name }) : null"
      [attr.aria-hidden]="!showLabel"
      [size]="size"
      (ready)="handleDesignReady('certificate')">
    </accredible-certificate>
  </ng-template>

  <ng-template #badgeTemplate>
    <accredible-badge *ngIf="group.badge_design"
      class="badge"
      [design]="group.badge_design"
      [partialCredential]="partialCredential"
      [attr.aria-label]="showLabel ? t('aria_badge', { courseName: group.course_name }) : null"
      [attr.aria-hidden]="!showLabel"
      (ready)="handleDesignReady('badge')">
    </accredible-badge>
  </ng-template>
</ng-container>
