import { AccredibleBadgeModule } from '@accredible-frontend-v2/components/badge';
import { AccredibleCertificateModule } from '@accredible-frontend-v2/components/certificate';
import { AccredibleCredentialAppearanceComponentModule } from '@accredible-frontend-v2/components/credential-appearance';
import { AccredibleLanguageServiceModule } from '@accredible-frontend-v2/services/language';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CredentialRendererLoadModule } from './credential-render-load.module';
import { CredentialRendererContainer } from './credential-renderer.container';
import { RendererService } from './services/renderer.service';

@NgModule({
  declarations: [CredentialRendererContainer],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AccredibleCertificateModule,
    AccredibleBadgeModule,
    CredentialRendererLoadModule,
    AccredibleCredentialAppearanceComponentModule,
    AccredibleLanguageServiceModule.forRoot(),
  ],
  providers: [RendererService],
  bootstrap: [CredentialRendererContainer],
})
export class CredentialRendererModule {}
